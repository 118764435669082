import http from "@/common/http";
import config from "@/common/config";

const Api = {};

const authToken = window.btoa(
  `${config.basicAuthUsername}:${config.basicAuthPassword}`
);

/**
 * @description 上传
 */
Api.uploadFile = (opts) =>
  http.request({
    url: "/api/v4/nicp/files/file",
    data: opts,
    headers: {
      "content-type": "application/x-www-form-urlencoded",
    },
  });

/**
 * @description 登录
 */
Api.login = () => {
  return http.request({
    url: `/login/qrcode`,
    method: "GET",
  });
};

/**
 * @description 授权认证
 */
Api.process = (opts, headers) =>
  http.request({
    url: `/login/qrcode/process`,
    method: "POST",
    headers: {
      Authorization: `Basic ${authToken}`,
      ...headers,
    },
    data: opts,
  });

/**
 * @description 授权认证
 */
Api.processAssets = (opts, headers) =>
  http.request({
    url: `/login/qrcode/process`,
    method: "POST",
    headers: {
      Authorization: `Basic ${authToken}`,
      ...headers,
      platform: "FINANCE_ASSET_APP",
    },
    data: opts,
  });

/**
 * 密码登录
 * @param {*} opts
 * @returns
 */
Api.passwordLogin = (opts, headers) =>
  http.request({
    url: `/login/token`,
    method: "POST",
    headers: {
      Authorization: `Basic ${authToken}`,
      ...headers,
    },
    data: opts,
  });

/**
 * 移动端案件审核
 * @param {*} opts
 * @returns
 */
Api.approve = (opts) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceMobile/approve`,
    method: "POST",
    data: opts,
  });

/**
 * 移动端案件分派
 * @param {*} opts
 * @returns
 */
Api.assignDispatch = (opts) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceMobile/assign`,
    method: "POST",
    data: opts,
  });

/**
 * 法诉附件查询
 * @param {*} bizNo
 * @returns
 */
Api.attchment = (bizNo) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceMobile/attchment/${bizNo}`,
    method: "GET",
  });

/**
 * 移动端案件取消
 * @param {*} opts
 * @returns
 */
Api.cancel = (opts) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceMobile/cancel`,
    method: "POST",
    data: opts,
  });

/**
 * 客户基本信息查询
 * @param {*} opts
 * @returns
 */
Api.getBasicInfo = (opts) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceMobile/getBasicInfo`,
    method: "POST",
    data: opts,
  });

/**
 * 客户完整信息查询
 * @param {*} projectCode
 * @returns
 */
Api.getCompleteInfo = (projectCode) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceMobile/getCompleteInfo/${projectCode}`,
    method: "GET",
  });

/**
 * 查询商户收车委托授信函
 * @param {*} projectCode
 * @returns
 */
Api.getMerchantCollectionAuthorization = (projectCode) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceMobile/getMerchantCollectionAuthorization/${projectCode}`,
    method: "GET",
  });

/**
 * 查询委外收车委托授信函
 * @param {*} projectCode
 * @returns
 */
Api.getOutsourceAuthorization = (projectCode) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceMobile/getOutsourceAuthorization/${projectCode}`,
    method: "GET",
  });

/**
 * 委外收车信息查询
 * @param {*} projectCode
 * @returns
 */
Api.getOutsourceCase = (projectCode) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceMobile/getOutsourceCase/${projectCode}`,
    method: "GET",
  });

/**
 * 工单列表
 * @param {*} projectCode
 * @returns
 */
Api.getWorkOrder = (projectCode) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceMobile/getWorkOrder/${projectCode}`,
    method: "GET",
  });

/**
 * 最新标记
 * @param {*} projectCode
 * @returns
 */
Api.getCommonStatus = (projectCode) =>
  http.request({
    url: `/api/v4/ibp/outsource/configApi/getCommonStatus/${projectCode}`,
    method: "GET",
  });

/**
 * 最新标记
 * @param {*} projectCode
 * @returns
 */
Api.getCommonStatus = (projectCode) =>
  http.request({
    url: `/api/v4/ibp/outsource/configApi/getCommonStatus/${projectCode}`,
    method: "GET",
  });

/**
 * 通过 bizNo 查询借据详情
 * @param {*} projectCode
 * @returns
 */
Api.findByBizNo = (projectCode) =>
  http.request({
    url: `/api/v4/ibp/finance/iou/findByBizNo/${projectCode}`,
    method: "GET",
  });

/**
 * 查询最新标记
 * @param {*} projectCode
 * @returns
 */
Api.findRecordList = (opts) =>
  http.request({
    url: `/api/v4/ibp/outsource/commonMarkRecord/findList`,
    method: "POST",
    data: opts,
  });

/**
 * 查询最新标记
 * @param {*} projectCode
 * @returns
 */
Api.getHaveBeneficiaryName = (projectCode) =>
  http.request({
    url: `/api/v4/ibp/finance/beneficiary/haveBeneficiary?bizNo=${projectCode}`,
    method: "GET",
  });

/**
 * 清收机构
 * @param {*}
 * @returns
 */
Api.getOrganList = (data) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceOrganizationApi/findList`,
    method: "POST",
    data,
  });

/**
 * 生成委托函
 * @param {*}
 * @returns
 */
Api.generateAuthorization = (data) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceCaseApi/generateAuthorization`,
    method: "POST",
    data,
  });

/**
 * 结清试算
 * @param {Object} data
 * @returns
 */
Api.repayCalculate = ({ platform, ...data }) =>
  http.request({
    url: `/api/v4/ibp/finance/capitalTrial/repayCalculate/${platform}`,
    method: "POST",
    data,
  });

/**
 * 结清试算记录
 * @param {Object} data
 * @returns
 */
Api.repayFindPage = ({ pageNo, pageSize, ...data }) =>
  http.request({
    url: `/api/v4/ibp/finance/capitalTrial/findPage`,
    method: "POST",
    data,
    params: {
      pageNo,
      pageSize,
    },
  });

/**
 * 抢单机构
 * @param {Object} data
 * @returns
 */
Api.getOutsourceCaseApi = (data) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceCaseApi/findRefPage`,
    method: "POST",
    data,
    params: {
      pageNo: 1,
      pageSize: 99,
    },
  });

/**
 * 认证审核详情
 * @param {Object} data
 * @returns
 */
Api.getApproveInfo = (data) =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceCaseApi/approveInfo`,
    method: "POST",
    data,
  });

/**
 * 清收方式枚举
 * @param {Object} data
 * @returns
 */
Api.getRecoverWayEnum = () =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceEnumsApi/getRecoverWayEnum`,
    method: "GET",
  });
/**
 * 获取分派方式枚举
 * @param {Object} data
 * @returns
 */
Api.getRecoverModeEnum = () =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceEnumsApi/getRecoverModeEnum`,
    method: "GET",
  });
/**
 * 获取案件操作枚举
 * @param {Object} data
 * @returns
 */
Api.getCaseOperateTypeEnum = () =>
  http.request({
    url: `/api/v4/ibp/outsource/outsourceEnumsApi/getCaseOperateTypeEnum`,
    method: "GET",
  });

export default Api;
